import React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius: 8,
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFF'
  },
};

export default function RCModal() {
  const [modalIsOpen, setIsOpen] = React.useState(true);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      style={customStyles}
      isOpen={modalIsOpen}
      onRequestClose={onClose}
    >
      <div className='modal-header'>
        <button onClick={onClose} className='close'><FontAwesomeIcon style={{ fontSize: 23 }} icon={faXmark} size={'2xl'} /></button>
      </div>
      <div className='modal-body'>
      <iframe 
       
        src="https://www.youtube.com/embed/BmfVSAW_vuI?controls=0" 
        title="YouTube video player" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowFullScreen />
      </div>
    </Modal>
  );
}

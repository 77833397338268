import React, { useEffect } from 'react';
import '../assets/scss/main.scss';
import { Layout, SEO, Header, Services } from '../components';
import Certificaciton from '../components/certificaciton';
import Wallpaper from '../components/wallpaper';
import Housing from '../components/home/wrapper/housing';
import About from '../components/home/wrapper/about';
import Restaurant from '../components/home/wrapper/restaurant';
import Trails from '../components/home/wrapper/trails';
import Banner from '../components/home/banner';
import Footer from '../components/footer';
import Contact from '../components/contact';
import Video from '../components/video';
import IconWrapper from '../components/icon-wrapper';
import Map from '../components/map';
import Modal from '../components/modal';
import WOW from 'wowjs';

const IndexPage = () => {
  useEffect(() => {
    new WOW.WOW({
      animateClass: 'animated',
      offset: 140,
    }).init();
  }, []);

  return (
    <Layout>
      <SEO />
      <IconWrapper />
      <Header link={false} />
      <Banner />
      <Modal />
      <About />
      <Services />
      <Wallpaper number={2} nameImge={'wapper-min.jpeg'} />
      <Housing />
      <Wallpaper number={1} nameImge={'wapper-min.jpeg'} />
      <Trails />
      <Wallpaper number={3} nameImge={'wapper-min.jpeg'} />
      <Restaurant />
      <Wallpaper number={0} nameImge={'wapper-min.jpeg'} />
      <Contact />
      <Map />
      <Video />
      <Certificaciton />
      <Footer color="white" />
    </Layout>
  );
};

export default IndexPage;

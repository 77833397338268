import React from 'react';
import { wrapper } from '../config/home';

function Video() {
  const { items } = wrapper.videos;
  return (
    <div className="wrapper white video">
      {items.map((row, i) => {
        return (
          // eslint-disable-next-line react/jsx-key
          <div key={i} className="continer-video">
            <iframe
              width="350"
              height="250"
              src={row.url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        );
      })}
    </div>
  );
}

export default Video;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { wrapper } from '../../../config/home';
import { rowsItems } from '../../../utils';
import { Link } from 'gatsby';

const useHousingImg = () => {
  const data = useStaticQuery(graphql`
    {
      cabanasMin: file(relativePath: { eq: "housing/cabanas-min.jpg" }) {
        childImageSharp {
          sizes {
            srcWebp
          }
        }
      }
      campamento: file(relativePath: { eq: "housing/campamento-min.jpg" }) {
        childImageSharp {
          sizes {
            srcWebp
          }
        }
      }
    }
  `);
  return data;
};

const Housing = () => {
  const { id, title, items } = wrapper.housing;
  const rows = rowsItems(items);
  const data = useHousingImg();
  return (
    <section id={id} className="wrapper white">
      <div className="container">
        <div>
          <header>
            <h2 className="wow animate__animated animate__bounce animate__slideInDown">{`${title}`}</h2>
          </header>
        </div>
        {rows.map((row, i) => {
          let grid = 12 / row.length;
          return (
            <div key={i} className="row">
              {row.map(({ name, icon, description }, j) => {
                return (
                  <div
                    key={j}
                    className={`feature col-${grid}-xlarge  wow animate__animated animate__bounce animate__slideInUp`}
                  >
                    <div className="box">
                      <div className="box__img">
                        <img src={data[icon].childImageSharp.sizes.srcWebp} alt={name} />
                      </div>

                      <h2>{name}</h2>
                      <p>{description}</p>
                      <Link to="/housing" className="button-wrapper primary">
                        Ver mas
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Housing;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { wrapper } from '../../../config/home';
import { Link } from 'gatsby';

const useTrailsImg = () => {
  const data = useStaticQuery(graphql`
    {
      fluvial: file(relativePath: { eq: "trails/fluvial.jpg" }) {
        childImageSharp {
          sizes {
            srcWebp
          }
        }
      }
      guaviare: file(relativePath: { eq: "trails/rio-guaviare.jpeg" }) {
        childImageSharp {
          sizes {
            srcWebp
          }
        }
      }
    }
  `);
  return data;
};

const Trails = () => {
  const { id, title, items } = wrapper.trails;
  const data = useTrailsImg();

  return (
    <section id={id} className="wrapper green">
      <div className="container">
        <div className="row">
          <header>
            <h2 className="wow animate__animated animate__bounce animate__zoomIn">{`${title}`}</h2>
          </header>
        </div>
        {items.map((row, i) => (
          <div className="row" key={`${row}-${i} `}>
            <article className={`col-12 col-12-mobile services  ${row.float}  `}>
              {row.float === 'left' && (
                <img
                  className="image featured wow animate__animated animate__bounce animate__fadeInLeft"
                  src={data[row.icon].childImageSharp.sizes.srcWebp}
                  alt={`icon-${row.icon}`}
                />
              )}
              <div className="content content-img animated wow animate__animated animate__bounce animate__fadeInUp">
                <header>
                  <h2 className={`aling-${row.float}`}>{row.name}</h2>
                </header>
                <p>{row.description}</p>
                <Link to="/trails" className={`button-wrapper primary ${row.float}`}>
                  Ver mas
                </Link>
              </div>
              {row.float === 'right' && (
                <img
                  className="image featured wow animate__animated animate__bounce animate__fadeInRight"
                  src={data[row.icon].childImageSharp.sizes.srcWebp}
                  alt={`icon-${row.icon}`}
                />
              )}
            </article>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Trails;

import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { socialLinks } from '../../../config/config';
import { wrapper } from '../../../config/home';

const useHousingImg = () => {
  const data = useStaticQuery(graphql`
    {
      restaurant: file(relativePath: { eq: "restaurant.jpg" }) {
        childImageSharp {
          sizes {
            srcWebp
          }
        }
      }
    }
  `);
  return data;
};

const Restaurant = () => {
  const { id, title, items } = wrapper.restaurant;
  const { restaurant } = useHousingImg();
  return (
    <section id={id} className="wrapper white">
      <div className="container">
        <header>
          <h2 className="wow animate__animated animate__bounce animate__slideInDown">{`${title}`}</h2>
        </header>
        {items.map((row, i) => (
          <div className="row" key={`${row}-${i} `}>
            <article className="col-12 col-12-small services ">
              <img
                className="__image wow animate__animated animate__bounce animate__fadeInLeft"
                src={restaurant.childImageSharp.sizes.srcWebp}
                alt={`icon-${row.icon}`}
              />
              <div className="content wow animate__animated animate__bounce animate__fadeInRight">
                <p>{row.description}</p>
                <Link to={socialLinks.whatsapp} className={`button-wrapper primary ${row.float}`}>
                  Ver mas
                </Link>
              </div>
            </article>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Restaurant;

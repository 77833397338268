export function rowsItems(data) {
  let rows = [[], []],
    count = 0;
  data.forEach((memo, i) => {
    if (i === 3 || i === 6) {
      count++;
      rows[count].push({ ...memo });
      return;
    } else {
      rows[count].push({ ...memo });
    }
  });
  return rows;
}

export function rowsItemsImage(data) {
  let rows = [],
    count = 0,
    columns = 3;
  data.forEach((memo, i) => {
    if (!rows[count]) {
      rows[count] = [];
    }
    if (i === columns) {
      rows[count].push({ ...memo });
      count++;
      columns = columns + 3;
    } else {
      rows[count].push({ ...memo });
    }
  });
  return rows;
}

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { wrapper } from '../../../config/home';

const useHousingImg = () => {
  const data = useStaticQuery(graphql`
    {
      about: file(relativePath: { eq: "about/abaut.jpeg" }) {
        childImageSharp {
          sizes {
            srcWebp
          }
        }
      }
    }
  `);
  return data;
};

const About = () => {
  const { id, title, items } = wrapper.about;
  const { about } = useHousingImg();
  return (
    <section id={id} className="wrapper white">
      <div className="container">
        <header>
          <h2 className="wow animate__animated animate__bounce animate__zoomIn">{title}</h2>
        </header>
        {items.map((row, i) => (
          <div className="row" key={`${row}-${i} `}>
            <article className="col-12 col-12-small services ">
              <img
                className="__image wow animate__animated animate__bounce animate__fadeInLeft"
                src={about.childImageSharp.sizes.srcWebp}
                alt={`icon-${row.icon}`}
              />
              <div
                className="content wow animate__animated animate__bounce animate__fadeInRight"
                style={{ paddingTop: 0 }}
              >
                <p>{row.description}</p>
              </div>
            </article>
          </div>
        ))}
      </div>
    </section>
  );
};

export default About;
